import(/* webpackMode: "eager", webpackExports: ["ConfigurationProvider"] */ "/build/apps/chat/providers/ConfigurationProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/component-library/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/build/apps/chat/styles/global-pdf-viewer.css");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/helpers/themeDomOperations.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useCopyToClipboard.ts");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useFileUpload.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useIsTouchDevice.ts");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useKeyPress.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useLocalStorage.ts");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useRedirectToBasePath.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useScreens.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useWarnBeforeReload.ts");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/hooks/useWindowResize.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/ClientThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/FeatureFlagProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/ModalProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/NetPromoterScoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/NotificationProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/SentryProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/TermsAndConditionsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/ToastProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/providers/UserProfileProvider.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/403.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/404.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/429.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/500.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/501.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/DefaultLayout.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/ErrorPage.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/FolderPathSelection.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/OfflineHandler.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/ScrollWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/SpaceNotFoundPage.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/TermsAndConditions.tsx");
;
import(/* webpackMode: "eager" */ "/build/packages/shared-library/src/ThemeLoadingPage.tsx");
